
import { renderData } from "@libraryHelpers/dataComponents.js";

export default {
	name: "CoreBlockPencilBanner",
	props: {
		settings: {
			type: Object,
			required: true,
		},
		dataSite: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			bannerIsClosed: false,
		};
	},
	computed: {
		bodyCopy() {
			let copy = this?.settings?.bodyCopy || "";
			// some older have not saved banner as rich text still a text
			// checking if html exist if it doesn't adding a p tag by default
			if (!/<\/?[a-z][\s\S]*>/i.test(copy)) copy = `<p>${copy}</p>`;
			return renderData(copy, this.dataSite);
		},
		label() {
			return renderData(this.settings.buttonText, this.dataSite);
		},
		url() {
			return renderData(this.settings.buttonLink, this.dataSite);
		},
	},
};
